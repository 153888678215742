import React from 'react';
import handleSubscribe from '../../common/newsletter/subscribe';

import Header from "../../common/header/Header";
import Footer from '../../common/footer/Footer';

const M1Landing = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'

    const handleSubmit = (event) => {
        //Prevent default
        event.preventDefault();

        //Grab email
        const email = document.getElementById('email').value;

        handleSubscribe(email, false,
            function (message) {
                console.log('Success:', message);

                //Hide any error messages
                document.querySelector('.form-msg.bg-danger').classList.add('d-none');

                //Show success message
                const successMsg = document.querySelector('.form-msg.bg-success');
                successMsg.innerHTML = message;
                successMsg.classList.remove('d-none');
            },
            function (message) {
                console.error('Error:', message);

                //Hide any success messages
                document.querySelector('.form-msg.bg-success').classList.add('d-none');

                //Show error message
                const errorMsg = document.querySelector('.form-msg.bg-danger');
                errorMsg.innerHTML = message;
                errorMsg.classList.remove('d-none');
            });
    };

    return (
        <>
            <Header />
            <section className="moisture landing-page mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 order-md-1 order-1 order-lg-0">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <div className="sec-title__tagline">
                                        <h6>Moisture-One by PlantCare</h6>
                                        <span className="right"></span>
                                    </div>
                                    <h2 className="sec-title__title">Never over or under-water a plant again. Ever.</h2>
                                    <br />
                                    <p>
                                        PlantCare's Moisture-One Smart Home Plant Health Sensor enables even the most forgetful
                                        plant owners to keep their plants healthy longer by taking all the guesswork out of watering
                                        all types of plants by providing users real-time information on moisture-levels and alerting
                                        you when it rises above or below the optimal temperature for all kinds and types of plants.
                                    </p>
                                </div>

                                <form id="contact-form" className="default-form2 contact-form-validated" action="#" novalidate="novalidate">
                                    <div id="status-container" className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="form-msg p-2 m-2 bg-success border border-secondary rounded text-white d-none">.bg-success</div>
                                                <div className="form-msg p-2 m-2 bg-danger border border-danger rounded text-white d-none">.bg-danger</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="input-box">
                                                <input type="email" name="email" placeholder="Email Address" required="1" id="email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pb-4">
                                        <div className="col-lg-8">
                                            <div className="contact-one__form-btn">
                                                <button className="thm-btn" type="submit" onClick={handleSubmit} data-loading-text="Please wait...">
                                                    <span className="txt">Reserve your spot now!</span>&nbsp;
                                                    <i className="fa fa-angle-double-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 order-md-0 order-0 order-lg-1">
                            <div className="landing-page__img">
                                <img src={publicUrl + "assets/images/moisture-1/tv-stand.webp"} alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="moisture">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                                <img src={publicUrl + "assets/images/moisture-1/scale.webp"} alt="#" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                                <h2 className="sec-title__title">Compact Size</h2>
                                    <br />
                                                <p>
                                                    The Moisture-One by PlantCare is a compact yet powerful moisture sensor designed to blend seamlessly with your home or garden. Small enough to fit in any pot, it offers full Wi-Fi connectivity, enabling you to effortlessly monitor your plants' moisture levels, ensuring optimal care without compromising aesthetics.
                                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="moisture">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-md-1 order-1 order-lg-0">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Quickly view the moisture-level data over time</h2>
                                    <br />
                                    <p>
                                        Moisture-One's companion app from PlantCare allows you to quickly view the current
                                        moisture-level of any of your Moisture-One sensors as compared to the ideal moisture-level
                                        for the type of plant being monitored. Because Moisture-One utilizes Wi-Fi, you can access
                                        this information anywhere, from home or the road.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 order-md-0 order-0 order-lg-1">
                            <div className="">
                                <img src={publicUrl + "assets/images/moisture-1/chart.webp"} alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="moisture">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                                <img src={publicUrl + "assets/images/moisture-1/my-devices.webp"} alt="#" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">The plant health info you need when you need it</h2>
                                    <br />
                                    <p>
                                        With the PlantCare app, be notified on a regular basis or when moisture levels reach
                                        unhealthy levels for any sensor, ensuring you never forget to water a plant again.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="moisture">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-md-1 order-1 order-lg-0">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Manage one potted plant or an entire garden</h2>
                                    <br />
                                    <p>
                                        By supporting multiple Moisture-One sensors, PlantCare can support monitoring a single
                                        potted plant to an entire home garden. Enter custom values for optimal moisture levels
                                        in to account for usage in a multiple-plant garden, or rely on the presets provided.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 order-md-0 order-0 order-lg-1">
                            <div className="">
                                <img src={publicUrl + "assets/images/moisture-1/garden.webp"} alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="moisture">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-0">
                            <div className="">
                                <img src={publicUrl + "assets/images/moisture-1/home-automation.webp"} alt="#" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Flexibility within Home Automation setups</h2>
                                    <br />
                                    <p>
                                        While the Moisture-One is built specifically to operate with the PlantCare app, the sensors
                                        do offer users the ability to integrate the sensor into other environments. 
                                        {/* See below for
                                        a list of support integrations: */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="moisture">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-md-1 order-1 order-lg-0">
                            <div className="contact-one__form contact-one__form--contact">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">Specs</h2>
                                    <br />
                                    <ul className="list-check">
                                        <li>✓ Wi-Fi enabled</li>
                                        <li>✓ Rechargeable battery</li>
                                        <li>✓ Customizable moisture levels</li>
                                        <li>✓ Supports multiple plants</li>
                                        <li>✓ 304 Stainless receptors</li>
                                        <li>✓ L x W x D: 127 x 29 x 13.8 millimeters*</li>
                                        <li>✓ Weight: 30 grams*</li>
                                        <li>* Product shown is a prototype and may change in final production</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 order-md-0 order-0 order-lg-1">
                            <div>
                                <img src={publicUrl + "assets/images/moisture-1/spec.webp"} alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default M1Landing;